function Footer() {

   
return(
<footer className="mt-8 pb-16 lg:pb-4">
 <p className='text-center' >&#169; 2023 | Brice Chen </p>
</footer>

)
}
export default Footer;
